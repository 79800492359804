import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  addCloud,
  HelpCloudServices,
  HyperconvergenceTech,
  amazonServices,
  azure,
  cloudBackup,
  googleCloud,
  managedInfraServices,
  office365,
} from "../images/cloudservices"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: office365,
    title: "Microsoft office 365",
    content:
      "With its many platforms and digital tools, Office 365 can significantly increase the productivity and performance of your business. Our tech gurus offer Office 365 management, covering everything from configuration to coaching. This service will help you to get the best out of Office 365 and use it to the advantage of your business.",
    order: 0,
  },
  {
    id: 2,
    src: azure,
    title: "Microsoft Azure",
    content:
      "Microsoft Azure is a public Cloud service that allows you to create and manage applications in a flexible and cost-efficient way. Along with setting up Microsoft Azure for your business, we also offer everything from migration services to recovery to consultancy. ",
    order: 1,
  },
  {
    id: 3,
    src: cloudBackup,
    title: "Cloud Backup and Disaster Recovery Services",
    content:
      "Cloud backup and disaster recovery are crucial for any modern business. Our Cloud backup and disaster recovery services will ensure that your data is safe and easily reinstated in the event of an emergency. Using premium tools and strategies, we’ll protect your data and keep it secure so you can focus on the core of your business.",
    order: 0,
  },
  {
    id: 4,
    src: amazonServices,
    title: "Amazon Services",
    content:
      "We know that Amazon Services can drive growth in your business. That’s why we offer setup, consultancy, and management of all the resources that Amazon has to offer. Enjoy benefits like database storage, content delivery, and valuable analytics such as Amazon Cloud Search, and watch your business thrive.",
    order: 1,
  },
  {
    id: 5,
    src: googleCloud,
    title: "Google Cloud",
    content:
      "A prime IT environment is vital for a modern successful business. We offer set up, management, and optimisation of the Google Cloud to help your business perform at its best while benefitting from services such as data analytics and the flexibility of serverless computing.Internet firewall management will help to protect your business network against cyberattacks. Configuration, setup, and management of your firewall is all included to ensure that you are well-defended against any internet activity that poses a risk to your business.",
    order: 0,
  },
  {
    id: 6,
    src: HyperconvergenceTech,
    title: "Hyperconvergence Technology",
    content:
      "Hyperconvergence technology decreases your costs and data centre complexity by combining storage, networking, and computing into a single system. This service also leads to a more reliable storage system with increased scalability, giving your business the boost it needs to reach its goals.",
    order: 1,
  },
  {
    id: 7,
    src: managedInfraServices,
    title: "Managed Infrastructure Services",
    content:
      "Save money and improve the productivity of your IT system with our managed infrastructure services. We take care of keeping your platforms, network, sites, and servers secure, updated, accessible, and operating at an optimal level so you won’t have to waste your valuable time worrying about IT.",
    order: 0,
  },
]

const points = [
  {
    id: 1,
    point: "Increased productivity and performance",
  },
  {
    id: 2,
    point: "Decreased costs",
  },
  {
    id: 3,
    point: "Secure protection and backup of important data",
  },
  {
    id: 4,
    point: "Improved flexibility when it comes to managing and accessing data",
  },
  {
    id: 5,
    point:
      "Access to helpful tools and solutions that will take your business all the way, including Office 365 and Amazon Services",
  },
]

export default function CloudServices() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/cloudServices.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Cloud Services" />
      <PageHeader
        fluid={index}
        title="Access what you need, anywhere, anytime."
        subtitle="Take your business to new heights"
        catchy="Future-proof your business today"
      />
      <IntroSection
        title="Cloud Services"
        content="Moving your business to a Cloud-based system has several advantages, ranging from real-time analytics to data collection to increased cyber security. Additionally, our Cloud services can help your business to increase productivity and meet your objectives while simultaneously decreasing costs. Work with us to access your company’s data with ease and innovate the way you do business."
        src={addCloud}
      />
      <BlackSection title="Take your business to new heights" />
      <IntroSection
        title="How we will help you?"
        content="Cloud-based services are a pioneering and affordable solution that gives your business the edge it needs to succeed. By utilising our Cloud services, you will experience an efficient method of data storage, improved automation and data collection, data analysis, enhanced security, and stronger defence against cyber threats. All of this leads to a seamless IT climate that will dramatically increase productivity in your business and stimulate more growth. Plus, by using monthly budgeting, our Cloud services are typically more cost-effective than more traditional methods of data storage."
        src={HelpCloudServices}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection
        paragraph1="Prepare your business for the future with our Cloud services. Access the information you need anywhere, anytime, on any device, and witness the change in your organisation. After using our Cloud services, you’ll experience:"
        paragraph2="Moving to a Cloud-based system might be a change, but the advantages are proven. Take the first step towards a more efficient business and speak to our team about our Cloud services today."
      >
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Call us today to take your business to new heights" />
    </Layout>
  )
}
